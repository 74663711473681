import React, { useContext, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import {
  setActiveDisclaimer,
  setActiveDisclaimerPopover,
} from "../../../contexts/Disclaimers/actions"
import { Disclaimer } from "../../../contexts/Disclaimers/disclaimers.d"
import { ProgressRing, X, DisclaimerInfo } from "../../atoms/Icon"
import { Popover } from "../../atoms/Popover"
import { DisclaimerPopoverProps } from "./DisclaimerPopover.d"
import useComponentId from "../../../hooks/useIdGenerator"
import { LanguageContext } from "../../../contexts/Language"

/**
 * Disclaimer Popover
 *
 * @component Disclaimer Popover
 * @summary - A popover for a disclaimer and a button that triggers opening the popover.
 *
 * @param {function} onClick - onClick function for View All Disclaimers button inside popover.
 * @param {string} code - Disclaimer code
 * @param {number} parentLeft - Optional DOMRect left value for parent element. Used to reposition disclaimers to prevent popover from being outside of parent container. Default behavior is to reposition the popover to keep it within document clientWidth.
 * @param {number} parentRight - Optional DOMRect right value for parent element. Used to reposition disclaimers to prevent popover from being outside of parent container. Default behavior is to reposition the popover to keep it within document clientWidth.
 * @returns <div></div> - containing disclaimer popover trigger and disclaimer popover
 */

const DisclaimerPopover: React.FC<DisclaimerPopoverProps> = ({
  onClick,
  code,
  parentLeft = null,
  parentRight = null,
  ...remainingProps
}): JSX.Element => {
  const uuid = useComponentId()
  const { language, _ } = useContext(LanguageContext)
  const [{ disclaimers, openOnLoad, activeDisclaimerPopoverId }, dispatch] =
    useContext(DisclaimersContext)
  let filteredDisclaimers: Disclaimer
  const filteredResult = disclaimers?.filter(
    disclaimer => disclaimer.code === code,
  )

  if (filteredResult?.length > 0) {
    filteredDisclaimers = filteredResult[0]
  }
  const { width, height } = remainingProps

  const AUTO_DISMISS_TIME = 6000
  const buttonRef = useRef()
  const popOverRef = useRef(null)
  const pointerRef = useRef(null)
  const [popover, setPopover] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [isOpenOnLoad, setIsOpenOnLoad] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const { ref, inView, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  useEffect(() => {
    if (!openOnLoad || !inView) return

    if (inView && openOnLoad.includes(code)) {
      setIsOpenOnLoad(true)
      openPopover()
    }
  }, [openOnLoad, inView])

  useEffect(() => {
    if (!isOpenOnLoad) return
    const timer = setInterval(() => {
      setProgress(progress => {
        if (progress + 1 <= 100) {
          return progress + 1
        }
      })
    }, AUTO_DISMISS_TIME / 100)
    return () => clearInterval(timer)
  }, [isOpenOnLoad])

  useEffect(() => {
    const isNotSelected = isOpenOnLoad
      ? false
      : activeDisclaimerPopoverId != uuid
    if (progress === 100 || isNotSelected) {
      closePopover()
    }
  }, [progress, activeDisclaimerPopoverId])

  // Preventing popover from going off screen
  useEffect(() => {
    if (popOverRef.current && open) {
      const popOverRect = popOverRef.current.getBoundingClientRect()
      let minLeft = parentLeft === null ? 0 : parentLeft
      let maxRight =
        parentRight === null
          ? document.documentElement.clientWidth
          : parentRight

      if (popOverRect?.left < minLeft) {
        const left = minLeft - popOverRect.left
        popOverRef.current.style.transform = `translateX(${Math.abs(left)}px)`
        pointerRef.current.style.transform = `translateX(${
          left > 0 ? -left : left
        }px)`
      } else if (popOverRect.right > maxRight) {
        popOverRef.current.style.transform = `translateX(${
          maxRight - popOverRect.right
        }px)`
        pointerRef.current.style.transform = `translateX(${
          popOverRect.right - maxRight
        }px)`
      }
      const handleClickOutside = (event: { target: any }) => {
        if (popOverRef.current && !popOverRef.current.contains(event.target)) {
          closePopover(event)
        }
      }
      // Attach the listeners on component mount.
      document.addEventListener("mousedown", handleClickOutside)
      // Detach the listeners on component unmount.
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [open])

  const closePopover = e => {
    e?.stopPropagation()
    if (!open) return
    setOpen(false)
    activeDisclaimerPopoverId && dispatch(setActiveDisclaimerPopover(null))
    dispatch(setActiveDisclaimer(null))
    isOpenOnLoad && setIsOpenOnLoad(false)
    setTimeout(() => {
      setPopover(false)
    }, 150)
  }

  const openPopover = e => {
    e?.stopPropagation()
    if (open) return
    if (!isOpenOnLoad) {
      dispatch(setActiveDisclaimer(code))
      dispatch(setActiveDisclaimerPopover(uuid))
    }
    setPopover(true)
    setTimeout(() => {
      setOpen(true)
    }, 20)
  }

  if (!filteredDisclaimers) return null

  let disclaimer
  if (language === "es") {
    disclaimer = filteredDisclaimers?.disclaimer
      ? filteredDisclaimers?.disclaimer
      : filteredDisclaimers?.disclaimer_es
  } else {
    disclaimer = filteredDisclaimers?.disclaimer
      ? filteredDisclaimers?.disclaimer
      : filteredDisclaimers?.disclaimer_en
  }
  let extraDisclaimer = filteredDisclaimers?.extraDisclaimer

  // For disclaimers that are inside modal on mobile or any other case where a different width is needed
  const dynamicStyles = width
    ? ` width: ${width}px; margin-left: -${width / 2}px; `
    : " width: 350px;  margin-left: -180px; "

  return (
    <div css={[tw`inline relative `]} ref={ref}>
      <button
        onClick={e => openPopover(e)}
        ref={buttonRef}
        css={[
          tw`focus-visible:(outline-[1px dotted currentColor])`,
          "outline-offset: 2px !important;",
        ]}
        aria-label="Disclosure"
      >
        <DisclaimerInfo css={[tw`h-3`]} />
      </button>
      {popover && (
        <div
          css={[
            tw`absolute whitespace-normal p-6 max-w-[100vw] mt-2 text-gray-900 bg-gray-100 border-gray-200 border shadow-lg opacity-0 transform translate-y-2 transition duration-150 top-[100%] left-0 z-[996] rounded-lg`,
            "text-shadow: none",
            open && tw`opacity-100 translate-y-0`,
            dynamicStyles,
            height && `height: ${height}px;`,
          ]}
          ref={popOverRef}
        >
          <div
            css={[
              tw`h-0 w-0 border-l-[12px] border-r-[12px] border-l-transparent border-r-transparent border-b-8 border-b-gray-100 absolute bottom-[100%] left-1/2`,
            ]}
            ref={pointerRef}
          />
          {isOpenOnLoad && (
            <ProgressRing
              color="gray-600"
              progress={progress}
              css={[tw`h-4 w-4 top-2 right-2 absolute`]}
            />
          )}
          <button
            css={[
              tw`absolute right-2 top-2 border-2 border-transparent rounded-full`,
            ]}
            onClick={e => closePopover(e)}
          >
            <X
              color="gray-600"
              css={[tw`h-3`, isOpenOnLoad && tw`transform scale-50`]}
            />
          </button>
          <div
            css={[
              tw`font-book text-xs max-h-80 overflow-y-auto`,
              height && `max-height: ${height - 40}px;`,
            ]}
          >
            <p
              className="disclaimer"
              css={[tw`italic mb-4 text-left tracking-normal normal-case`]}
              dangerouslySetInnerHTML={{
                __html: disclaimer,
              }}
            />
            {extraDisclaimer && (
              <p
                className="disclaimer"
                css={[tw`italic mb-4 text-left tracking-normal normal-case`]}
                dangerouslySetInnerHTML={{
                  __html: extraDisclaimer,
                }}
              />
            )}
            <div css={[tw`flex justify-between`]}>
              <button
                css={[tw`underline`]}
                onClick={e => {
                  closePopover(e)
                  onClick(code)
                }}
              >
                {_("View All Disclosures")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DisclaimerPopover
